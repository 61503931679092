
import { AuthModule } from '@/store/modules';
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import isMobile from '@/utils/mobile-detect';

@Component
export default class FoodBankTabs extends Vue {
  activetab = 'offers';
  @Prop() activetabProp!: string;
  isMobileView = false;

  created(): void {
    if (isMobile()) {
      this.isMobileView = true;
    }

    if (this.$route.query.myoffers) {
      this.activetab = 'myoffers';
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  mounted(): void {
    this.$root.$on('changeRouteMain', (menuText: string) => {
      this.activetab = 'offers';
    });
  }

  @Emit('switchtab')
  switchTab(tab: string): string {
    this.activetab = tab;
    return tab;
  }

  @Emit('scrolltoboosters')
  scrollToBoosters(isMobileView: boolean): boolean {
    isMobileView = false;

    if (isMobile() === true) {
      isMobileView = true;
    }

    return isMobileView;
  }

  get isAuthenticated(): boolean {
    return AuthModule.isAuthenticated;
  }
}
